<template>
  <header class="header-main-wrap dashboard-header-main-wrap">
    <div class="dashboard-header-wrap">
      <div class="d-flex align-items-center">
        <div class="dashboard-header-left flex-grow-1">
          <h1>Pipeline</h1>
        </div>
        <div class="dashboard-header-right">
          <button class="btn btn-primary" @click.prevent="refTaskManagerDialog.showDialog()">Nuevo Negocio</button>
        </div>
      </div>
    </div>
  </header>
  <div class="overlay-loading-pipelines" v-if="fetchPipelines">
    <div class="icon"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'><circle fill='#00aeff' stroke='#00aeff' stroke-width='15' r='15' cx='35' cy='100'><animate attributeName='cx' calcMode='spline' dur='2' values='35;165;165;35;35' keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1' repeatCount='indefinite' begin='0'></animate></circle><circle fill='#00aeff' stroke='#00aeff' stroke-width='15' opacity='.8' r='15' cx='35' cy='100'><animate attributeName='cx' calcMode='spline' dur='2' values='35;165;165;35;35' keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1' repeatCount='indefinite' begin='0.05'></animate></circle><circle fill='#00aeff' stroke='#00aeff' stroke-width='15' opacity='.6' r='15' cx='35' cy='100'><animate attributeName='cx' calcMode='spline' dur='2' values='35;165;165;35;35' keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1' repeatCount='indefinite' begin='.1'></animate></circle><circle fill='#00aeff' stroke='#00aeff' stroke-width='15' opacity='.4' r='15' cx='35' cy='100'><animate attributeName='cx' calcMode='spline' dur='2' values='35;165;165;35;35' keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1' repeatCount='indefinite' begin='.15'></animate></circle><circle fill='#00aeff' stroke='#00aeff' stroke-width='15' opacity='.2' r='15' cx='35' cy='100'><animate attributeName='cx' calcMode='spline' dur='2' values='35;165;165;35;35' keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1' repeatCount='indefinite' begin='.2'></animate></circle></svg></div>
    <div class="description">Cargando Pipelines</div>
  </div>
  <VueDraggable
    v-else
    v-model="pipelines"
    draggable="false"
    :animation="120"
    ghostClass="ghost"
    class="pipeline-container"
    group="pipelines"
  >
    <div class="pipeline-column" :data-pipeline-id="pipeline.id" v-for="pipeline in pipelines">
      <header>
        <div>{{ pipeline.name }}</div>
        <div @click="refTaskManagerDialog.showDialog(null, pipeline.id)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
          </svg>
        </div>
      </header>
      <VueDraggable
        v-model="pipeline.tasks"
        animation="120"
        class="pipeline-tasks"
        ghostClass="ghost"
        group="tasks"
        @update="onUpdate"
        @add="onAdd"
      >
        <div
          class="pipeline-task"
          :data-task-id="task.id"
          v-for="task in pipeline?.tasks" :key="task?.id"
          @click.prevent="refTaskManagerDialog.showDialog(task)"
        >
          <div class="status" :class="task.status">{{ TaskStatusValues[task.status] }}</div>
          <div class="title">{{ task.client_name }}</div>
          <div>${{ task.budget }}</div>
          <div style="margin-bottom: 2px;">{{ TaskPurposeValues[task.purpose] }}</div>
          <div class="date">
            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V8H19V19Z" /></svg></div>
            <div>{{ task.due_date }}</div>
          </div>
        </div>
      </VueDraggable>
    </div>
  </VueDraggable>
  <TaskManagerDialog
    :pipelines="pipelinesOptions"
    ref="refTaskManagerDialog"
    @updateTask="getPipelines()"
  />
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import TaskManagerDialog from '@/components/TaskManagerDialog.vue'
import {jsonToFormData} from '@/functions'
import {TaskStatusValues} from '@/values/TaskStatusValues'
import {TaskPurposeValues} from '../values/TaskPurposeValues'

const emit = defineEmits(['update:modelValue'])
const props = defineProps()
const fetchPipelines = ref(false)
const pipelines = ref([])
const pipelinesOptions = computed(() => pipelines.value.map(pipeline => ({label: pipeline.name, value: pipeline.id})))
const refTaskManagerDialog = ref(null)

onMounted(() => {
  getPipelines()
})

async function getPipelines () {
  fetchPipelines.value = true
  await fetch(`${taskLeadManagerAjax.ajax_url}?action=get_pipelines`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => response.json())
  .then(data => {
    fetchPipelines.value = false
    pipelines.value = data
  })
  .catch(error => {
    fetchPipelines.value = false
    alert(error)
    console.error('Error en la solicitud:', error)
  })
}

function onUpdate(e) {
  console.log('update', e)
}

function onAdd(e) {
  console.log('add', {
    toNewPipelineId: e.to.parentElement.dataset.pipelineId,
    taskId: e.data.id,
    position: e.newIndex
  })

  fetch(`${taskLeadManagerAjax.ajax_url}?action=update_task_pipeline`, {
    method: 'POST',
    body: jsonToFormData({
      task_id: e.data.id,
      new_pipeline_id: e.to.parentElement.dataset.pipelineId,
      position: e.newIndex
    })
  })
  .then(response => response.json())
  .catch(error => {
    console.error('Error en la solicitud:', error)
  })
}
</script>

<style>
.ghost {
  opacity: 0.4;
}
.pipeline-container {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  gap: 1rem;
  align-items: start;
  padding: 20px 20px 20px 255px;
}

.pipeline-column {
  min-width: 300px;
  height: 100%;
  min-height:calc(100svh - 155px);
  padding: 18px;
  display: flex;
  flex-direction: column;

  header {
    font-size: 17px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 25px;
    color: #181922;
    display:flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      fill: #181922;
      cursor: pointer;
      margin: 6px 0 6px 6px;
    }
  }

  .pipeline-tasks {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
  }

  .pipeline-task {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    user-select: none;
    cursor: pointer;

    .status {
      border-radius: 50px;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 16px;
      width: 100px;
      height: 25px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &.success {
        background-color: #03A88B;
      }
      &.progress {
        background-color: #FFC107;
      }
      &.cancelled {
        background-color: #F44336;
      }
    }

    .title {
      color: #252733;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      width: 100%;
      word-wrap: break-word;
    }

    .client_name {
      font-size: 13px;
      color: #656565;
      line-height: 1;
      margin-top:15px;
      margin-bottom:8px;
      width: 100%;
      word-wrap: initial;
    }

    .date {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 13px;
      color: #656565;
      line-height: 1;
      word-wrap: initial;
      border-radius: 50px;
      background-color: #fafafa;
      width: fit-content;
      text-align: center;
      padding: 3px 8px;
      margin-bottom: 10px;

      svg {
        width: 16px;
        height: 16px;
        fill: #656565;
      }
    }

    .description {
      font-size: 13px;
      color: #656565;
      line-height: 1;
      padding-bottom: 8px;
      width: 100%;
      font-style: italic;
      word-wrap: initial;
    }
  }

  .new-task-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03A88B;
    border: 1px dashed #01856d;
    border-radius: 5px;
    padding: 5px 12px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s;
    font-size: 14px;
    color: #fff;

    &:hover {
      background-color: #01856d;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
      margin-left: 6px;
    }
  }
}
.overlay-loading-pipelines {
  width: 100%;
  height: 100vh;
  padding: 0px 0px 0px 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .description {
    font-size: 17px;
    color: #656565;
    line-height: 1;
  }
}
</style>
